import React, { useEffect, useState } from "react";
import { FormHelperText } from "@mui/material";
import { FaDownload } from "react-icons/fa";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import "./CsvToJsonConverter.css";

interface Props {
    template: (data: any) => void;
    setRows: (data: any[]) => void;
    newFile: boolean;
    setNewFile: (data: boolean) => void;
    skipLines?: number;
}

//Purpose of the Csv to JSON Compoent
const CsvToJsonConverter: React.FC<Props> = ({ template, setRows, newFile, setNewFile, skipLines }) => {
    const [fileName, setFileName] = useState<string | null>(null);
    const [dragOver, setDragOver] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    useEffect(() => {
        if (newFile === true) {
            setFileName(null);
            setRows([]);
            setNewFile(false);
        }
    }, [newFile])

    const csvToJSON = (csv: string, objTemplate: Object) => {
        const lines = csv.split(/\r?\n/);

        const result = lines.slice(skipLines).map((line, index) => {
            const values = line.split(';').map(value => value.trim());

            if (values.every(value => value === "")) {
                return null;
            }

            const obj: any = { id: index };

            Object.keys(objTemplate).forEach((key, i) => {
                obj[key] = decodeURIComponent(values[i] || "");
            });

            return obj;
        }).filter(obj => Object.values(obj).some(val => val !== ''));

        return result;
    };

    const isValidRow = (row: any, template: any) => {
        return Object.keys(template).every(key => row[key] !== '');
    };

    const handleFileUpload = (file: File) => {
        // Check if the uploaded file is an CSV file
        if (!file.name.endsWith(".csv")) {
            // Show error message
            setErrorMessage("Oups ! Quelque chose ne va pas. Veuillez envoyer un fichier CSV.");
            return;
        }

        setErrorMessage(null);

        const reader = new FileReader();

        reader.onload = async (event) => {
            if (event.target && event.target.result) {
                const text = event.target?.result as string;
                const data = csvToJSON(text, template);
                if (data.some(row => !isValidRow(row, template))) {
                    const invalidRows = data.filter(row => !isValidRow(row, template));
                    console.log("Lignes invalides:", invalidRows);
                    setNewFile(true);
                    setErrorMessage("Le fichier CSV contient des lignes avec des champs manquants. Veuillez vérifier le fichier et réessayer.");
                } else {
                    setErrorMessage(null);
                    setRows(data);
                }
                setFileName(file.name);
            } else {
                console.error("Failed to read the file.");
            }
        };

        reader.readAsText(file, 'UTF-8');
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setDragOver(false);
        const file = event.dataTransfer.files?.[0];
        if (file) {
            if (!file.name.endsWith(".csv")) {
                setErrorMessage("Oups ! Quelque chose ne va pas. Veuillez envoyer un fichier CSV.");
                return;
            } else {
                setErrorMessage(null);
                setFileName(file.name);
                handleFileUpload(file);
            }
        }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setDragOver(true);
    };

    const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setDragOver(true);
    };

    const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setDragOver(false);
    };

    return (
        <div
            style={{
                margin: "16px 16px 16px 0px",
                width: `${fileName ? "auto" : "100%"}`,
            }}
        >
            <label htmlFor="inputFile" className="label-file">
                <div
                    className={`drop-area ${dragOver ? "drag-over" : ""} ${fileName ? "file-uploaded" : ""
                        }`}
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    style={{ display: "flex", justifyContent: "center" }}
                >
                    {!fileName && (
                        <>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    height: "45vh",
                                }}
                            >
                                <FaDownload style={{ fontSize: "24px", margin: "10px" }} />
                                <p style={{ padding: "10px", display: "flex" }}>
                                    Déposer votre fichier ici ou{" "}
                                    <div className="input2">
                                        {" "}
                                        &nbsp; Cliquez pour selectionner{" "}
                                    </div>
                                </p>
                                <input
                                    id="inputFile"
                                    type="file"
                                    accept=".csv"
                                    onChange={(event) => {
                                        const file = event.target.files?.[0];
                                        if (file) {
                                            handleFileUpload(file);
                                        }
                                    }}
                                    className="input-file"
                                />
                                {errorMessage && (
                                    <FormHelperText sx={{ display: "flex", alignItems: "center", gap: "5px" }} error>
                                        <InfoOutlinedIcon />
                                        {errorMessage}
                                    </FormHelperText>
                                )}
                            </div>
                        </>
                    )}

                    {fileName && (
                        <div className="file-name" style={{ display: "block" }}>
                            Nom du fichier : {fileName}
                        </div>
                    )}
                </div>
            </label>
        </div>
    );
};

export default CsvToJsonConverter;